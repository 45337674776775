import NavBar from "./components/navBar";
import Home from "./components/home";
import About from "./components/about";
import Services from "./components/services";
import ContactUs from "./components/contactUs";
import Footer from "./components/footer";
import Benefits from "./components/benefits";
import "./styles/app.css";
{/*import Us from "./components/us";*/}

const App = () => {
  return (
    <div className="App">
      <NavBar />
      <Home />
      <About />
      <Benefits />
      {/*<Us />*/}
      <Services />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
