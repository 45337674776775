import { motion } from "framer-motion";
import "../styles/services.css";
import Service1PNG from "../assets/service1.png";
import Service2PNG from "../assets/service2.png";
import Service3PNG from "../assets/service3.png";
import Service4PNG from "../assets/service4.png";
import Service5PNG from "../assets/service5.png";
import Service6PNG from "../assets/service6.png";

const fadeInAnimation = {
  initial: { y: 50 },
  whileInView: {
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.5,
      duration: 2.5,
    },
  },
  viewport: { once: true, amount: 0.6 },
};

const ServiceCard = ({ icon, title, classNames }) => {
  return (
    <motion.div
      className={`min-w-[230px] max-w-[500px] serviceCard ${classNames} flex flex-col items-center justify-center text-center`}
      initial={fadeInAnimation.initial}
      whileInView={fadeInAnimation.whileInView}
      viewport={fadeInAnimation.viewport}
    >
      <div className="h-[60%]">
        <img src={icon} className="pt-8 h-[100px]" alt={title} />
      </div>
      <div className="pl-4 pr-4">
        <p className="text-base font-bold leading-5 mb-4">{title}</p>
      </div>
    </motion.div>
  );
};

const Workshops = () => {
  const WorkshopCard = ({ icon, title, classNames }) => {
    return (
      <motion.div
        className={`min-w-[230px] max-w-[500px] workshopCard ${classNames} flex flex-col items-center justify-center text-center rounded-3xl shadow-xl`}
        initial={fadeInAnimation.initial}
        whileInView={fadeInAnimation.whileInView}
        viewport={fadeInAnimation.viewport}
        style={{
          background: "linear-gradient(to bottom, #420CF4, #F9664F)",
        }}
      >
        <div className="h-[60%]">
          <img src={icon} className="py-4 h-[100px]" alt={title} />
        </div>
        <div className="px-4">
          <p className="text-base text-white font-medium leading-5 mb-4">
            {title}
          </p>
        </div>
      </motion.div>
    );
  };

  return (
    <div className="flex justify-center gap-6 border-b-1 border-b-black">
      <WorkshopCard
        icon={Service4PNG}
        title="Diversidad e Inclusión"
        classNames="w-full px-6 my-6"
      />
      <WorkshopCard
        icon={Service6PNG}
        title="Liderazgo Positivo y Adaptado"
        classNames="w-full px-6 my-6"
      />
      <WorkshopCard
        icon={Service5PNG}
        title="Seguridad Psicológica"
        classNames="w-full px-6 my-6"
      />
    </div>
  );
};

const Services = () => {
  return (
    <div
      className="mx-[60px] max-sm:mx-[15px] my-[140px] max-sm:my-[80px]"
      id="services"
    >
      <p className="sm:text-7xl text-5xl font-extrabold text-center my-10">
        Servicios
      </p>

      <div className="w-fit mx-auto grid grid-cols-3 md:grid-cols-1 gap-8 -mb-5">
        <div className="col-span-3 md:col-span-1 block">
          <div className="flex flex-col md:flex-row justify-center gap-8 mt-10 md:mt-10">
            <ServiceCard
              icon={Service1PNG}
              title="Evaluación y certificación de entornos mentalmente saludables"
              classNames="md:col-span-1"
            />
            <ServiceCard
              icon={Service2PNG}
              title="Entrenamiento para líderes en manejo de crisis"
              classNames="md:col-span-1"
            />
            <ServiceCard
              icon={Service3PNG}
              title="Acompañamiento y guía en certificación de NOM-035"
              classNames="md:col-span-1"
            />
          </div>
        </div>

        <div className="grid col-span-3 md:flex gap-8 mt-10 md:mt-10 mx-auto justify-around">
          <div className="flex items-center mb-8 w-full md:w-5/12">
            <h1 className="text-2xl font-bold text-center md:text-left pl-1">
              También ofrecemos talleres y capacitaciones para empresas, asociaciones u organismos públicos en:
            </h1>
          </div>
          <div className="flex flex-nowrap flex-row justify-start overflow-x-scroll md:overflow-hidden md:justify-center ">
            <Workshops />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;