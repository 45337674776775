import { useState, useEffect } from "react";
import { Navbar as Nav, NavbarBrand, NavbarContent, NavbarItem, Link, Button, NavbarMenuToggle, NavbarMenuItem, NavbarMenu } from "@nextui-org/react";
import EMSLogo from '../assets/EMSLogo';
import { motion, useScroll } from "framer-motion";

const menuItems = [
  { name: "Inicio", href: "#home"  }, 
  { name: "Acerca de", href: "#about" }, 
  { name: "Servicios", href: "#services" },
  { name: "Contacto", href: "#contactUs" }
];
{/*{ name: "Nuestro Propósito", href: "#benefits" },*/}
{/*{ name: "Sobre Nosotros", href: "#us" },*/}

const variants = {
  visible: { opacity: 1, y: 0 },
  hidden: { opacity: 0, y: -25 }
};

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { scrollY } = useScroll();
  const [hidden, setHidden] = useState(false);
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    return scrollY.onChange(() => update());
  });

  const update = () => {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false);
    } else if (scrollY?.current > 100 && scrollY?.current > scrollY?.prev) {
      setHidden(true);
    }
    if (scrollY?.current > 280) {
      setIsTop(false);
    } else {
      setIsTop(true);
    }
  };

  const NavBarMenuItems = () => (
    menuItems.map((item, index) => (
      <NavbarMenuItem key={`${item}-${index}`} >
        <Link
          className="px-[40px] max-sm:px-[0px] w-full text-black"
          href={item.href}
          size="lg"
          onClick={() => setIsMenuOpen(false)}
        >
          {item.name}
        </Link>
      </NavbarMenuItem>
    ))
  );
  
  return (
    <motion.div 
      variants={variants}
      animate={hidden ? "hidden" : "visible"}
      transition={{ ease: [0.1, 0.25, 0.3, 0.3], duration: 0.2 }}
      className="fixed w-full z-50"
    >
      <Nav
        className="navBar h-[120px] px-[60px] max-sm:px-[10px] bg-transparent bg-gradient-diagonal"
        onMenuOpenChange={setIsMenuOpen}
        isMenuOpen={isMenuOpen}
        isBlurred={!isTop || isMenuOpen}
      >
        <NavbarContent>
          <NavbarBrand><EMSLogo classNames="min-w-[220px] max-w-[320px]" /></NavbarBrand>
        </NavbarContent>

        <NavbarMenu className="top-[120px] bg-transparent transition">
          <NavBarMenuItems />
        </NavbarMenu>

        <NavbarContent justify="end" className="hidden lg:flex gap-6">
          <NavbarItem>
            <Link className="navBarItem text-gray-150" href="#home">
              Inicio
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link className="navBarItem text-gray-150" href="#about">
              Acerca de
            </Link>
          </NavbarItem>
          {/*<NavbarItem>
            <Link className="navBarItem text-gray-150" href="#benefits">
              Nuestro Propósito
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Link className="navBarItem text-gray-150" href="#us">
              Sobre Nosotros
            </Link>
          </NavbarItem>*/}
          <NavbarItem>
            <Link className="navBarItem text-gray-150" href="#services">
              Servicios
            </Link>
          </NavbarItem>
          <NavbarItem>
            <Button className="navBarItemContact" as={Link} href="#contactUs" >
              Contacto
            </Button>
          </NavbarItem>
        </NavbarContent>
        
        <NavbarContent justify="end" className="lg:hidden">
          <NavbarMenuToggle 
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            className={`${isTop ? "text-white" : "text-black"}`}
          />
        </NavbarContent>
      </Nav>
    </motion.div>
  );
};

export default NavBar;