import { motion } from "framer-motion";
import EMSLogoWhite from "../assets/EMSLogoWhite";

const fadeInAnimation = {
  initial: { y: 50 },
  whileInView: {
    y: 0,
    transition: {
      type: "spring",
      bounce: 0.5,
      duration: 2.5,
    },
  },
  viewport: { once: true, amount: 0.6 },
};

const hoverAnimation = {
  whileHover: {
    y: -15,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 20,
    },
    cursor: 'pointer'
  },
};

const BigEMSService = ({ classNames }) => {
  return (
    <motion.div
      className={`bg-[#143294] flex flex-col items-center justify-center mx-4 my-4 h-[300px] w-full
      text-white text-center md:h-[400px] md:mx-16 md:my-0 md:w-6/12 ${classNames} serviceCard`}
      initial={fadeInAnimation.initial}
      whileInView={fadeInAnimation.whileInView}
      viewport={fadeInAnimation.viewport}
      whileHover={hoverAnimation.whileHover}
    >
      <EMSLogoWhite classNames="pb-6 min-w-[120px] max-w-[328px]" />
    </motion.div>
  );
};

const Benefits = () => {
  return (
    <div
      className="flex flex-col items-center justify-center bg-gradient-to-r from-blue-900 to-teal-500
      px-[10px] py-[60px] text-center sm:px-[60px] sm:pt-[140px] sm:pb-[100px]"
      id="benefits"
    >
      <div className="flex flex-col items-center justify-center md:flex-row">
        <p
          className="mx-4 mb-8 w-full px-4 text-base text-gray-200
          md:mx-16 md:mb-0 md:w-[60%] md:px-9 md:text-lg">
          En On Mental Otorgamos el primer sello EMS® en el mercado latinoamericano para destacar organizaciones como un entorno laboral mentalmente saludable.
          <br /><br />
          Esto significa que las empresas u organizaciones con este sello, son un entorno Wellbeing, garantizando para sus colaboradores seguridad psicológica y visibilizando un ambiente libre de factores estresantes.
          </p>
        <BigEMSService className="col-span-1 row-span-1 m-auto md:col-span-3 md:my-0 md:ml-8" />
      </div>
    </div>
  );
};

export default Benefits;