import { motion } from "framer-motion";

const fadeInAnimation = { 
  init: { x: -100, opacity: 0 },
  whileInView: { 
    x: 0, 
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 2
  }},
  viewport: { once: true, amount: 0.8 }
};

const Bulb = () => {
  return (
    <motion.svg 
      width="317" 
      height="461" 
      viewBox="0 0 317 461" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
      className="max-xl:w-[200px] w-[317px] h-[461px]"
      initial={fadeInAnimation.init}
      whileInView={fadeInAnimation.whileInView}
      viewport={fadeInAnimation.viewport}
    >
      <path d="M86.75 443.75H229.25M158 16.25C132.297 16.2438 107.071 23.1897 84.9935 36.3519C62.9163 49.514 44.8101 68.4024 32.5928 91.0162C20.3755 113.63 14.502 139.127 15.5946 164.807C16.6871 190.487 24.7049 215.393 38.7988 236.887C61.4563 271.372 72.7613 288.615 74.2575 291.18C87.32 314.099 84.9212 306.024 86.56 332.362C86.75 335.307 86.75 339.796 86.75 348.75C86.75 355.049 89.2522 361.09 93.7062 365.544C98.1602 369.998 104.201 372.5 110.5 372.5H205.5C211.799 372.5 217.84 369.998 222.294 365.544C226.748 361.09 229.25 355.049 229.25 348.75C229.25 339.796 229.25 335.307 229.44 332.362C231.102 306 228.656 314.099 241.742 291.18C243.215 288.615 254.567 271.372 277.201 236.864C291.289 215.37 299.301 190.466 300.391 164.79C301.48 139.113 295.605 113.621 283.389 91.0106C271.172 68.4006 253.068 49.5152 230.995 36.3542C208.921 23.1932 183.699 16.2466 158 16.25Z" stroke="url(#paint0_radial_324_206)" stroke-width="30" stroke-linecap="square" stroke-linejoin="round" />
      <path d="M224.346 129.674C209.511 129.674 203.446 119.165 210.823 106.274C215.084 98.802 212.544 89.2776 205.085 85.008L190.907 76.8794C184.432 73.0204 176.072 75.3194 172.22 81.8059L171.318 83.3659C163.942 96.2567 151.812 96.2567 144.354 83.3659L143.452 81.8059C139.764 75.3194 131.404 73.0204 124.93 76.8794L110.751 85.008C103.292 89.2776 100.752 98.8841 105.013 106.356C112.472 119.165 106.407 129.674 91.5722 129.674C83.0485 129.674 76 136.653 76 145.275V159.725C76 168.265 82.9665 175.326 91.5722 175.326C106.407 175.326 112.472 185.835 105.013 198.726C100.752 206.198 103.292 215.722 110.751 219.992L124.93 228.121C131.404 231.98 139.764 229.681 143.616 223.194L144.518 221.634C151.894 208.743 164.024 208.743 171.482 221.634L172.384 223.194C176.236 229.681 184.596 231.98 191.07 228.121L205.249 219.992C212.708 215.722 215.248 206.116 210.986 198.726C203.528 185.835 209.593 175.326 224.428 175.326C232.952 175.326 240 168.347 240 159.725V145.275C239.918 136.735 232.952 129.674 224.346 129.674ZM157.959 179.185C143.288 179.185 131.322 167.197 131.322 152.5C131.322 137.803 143.288 125.815 157.959 125.815C172.63 125.815 184.596 137.803 184.596 152.5C184.596 167.197 172.63 179.185 157.959 179.185Z" fill="url(#paint1_radial_324_206)" />
      <defs>
        <radialGradient id="paint0_radial_324_206" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(157.992 161) rotate(90) scale(282.75 188.535)">
          <stop stop-color="#71BACD" />
          <stop offset="0.713542" stop-color="#3707FF" />
        </radialGradient>
        <radialGradient id="paint1_radial_324_206" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(158 152.5) rotate(90) scale(77.5 82)">
          <stop stop-color="#71BACD" />
          <stop offset="1" stop-color="#3707FF" />
        </radialGradient>
      </defs>
    </motion.svg>
  );
}

export default Bulb;


