import React from 'react';
import { motion } from "framer-motion";

const spring = {
  type: "spring",
  stiffness: 1000,
  damping: 3
};

const BigHead = () => {
  return (
    <svg
      viewBox="0 0 1115 1139"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute -z-50 
        top-[-10px] right-[-130px]
        sm:top-[-80px] sm:right-[-100px] 
        max-w-[75vw] max-h-[100vh]"
    >
      <motion.path transition={spring} initial={{ y: -1000, x: 1000 }} animate={{ x: 0, y: 0, transition: { duration: 0.9 } }} d="M132.575 611.528C-7.24776 449.862 -81.9999 140 148.584 77.4945L858.964 88.5434C1001.11 90.7542 1117.26 204.507 1110.02 346.487C1104.14 461.953 1093.12 556.699 1082.97 597.46C1079.28 612.316 1069.68 624.049 1059.54 635.518C976.48 729.476 572.651 1179.02 487.78 1135.37C301.173 1039.39 327.688 940.201 191.109 931.463C54.5302 922.724 205.561 695.916 132.575 611.528Z" fill="url(#paint0_linear_28_536)" />
      <motion.path transition={spring} initial={{ y: -1000, x: 1000 }} animate={{ x: 0, y: 0, transition: { duration: 1 } }} d="M359.57 720.995C111.629 697.944 -16.3018 278.995 128.198 139.995L986.715 184.194C1013.88 185.593 1037.6 203.05 1047.02 228.574L1097.96 366.705C1103.24 381.024 1105 396.405 1103.07 411.545L1078 609L881.5 827.5C680.257 762.147 473.105 731.55 359.57 720.995Z" fill="url(#paint1_linear_28_536)" />
      <motion.path transition={spring} initial={{ y: -1000, x: 1000 }} animate={{ x: 0, y: 0, transition: { duration: 1.2 } }} d="M465.5 0C390.699 0 316.43 12.574 245.798 37.1965L179.199 60.413C163.939 65.7325 150.117 74.5031 138.792 86.0318C138.562 86.2661 138.331 86.5008 138.1 86.7358C125.897 99.1584 119.915 117.135 122.347 134.378C225.416 865.216 926.958 576.89 926.958 576.89C926.958 576.89 1089.5 541.5 1082 406.5C1077.03 317.021 1065.25 262.687 1057.87 236.219C1054.18 222.977 1046.85 211.251 1037.73 200.964L962.137 115.687C939.133 89.7354 909.4 70.6525 876.225 60.5489L843.229 50.5L759.5 25L669 7.5L578.5 0H465.5V0Z" fill="url(#paint2_linear_28_536)" />
      <defs>
        <linearGradient id="paint0_linear_28_536" x1="414.518" y1="127.985" x2="414.518" y2="886.353" gradientUnits="userSpaceOnUse">
          <stop offset="0.31824" stop-color="#3707FF" />
          <stop offset="0.901042" stop-color="#71BACD" />
        </linearGradient>
        <linearGradient id="paint1_linear_28_536" x1="500.523" y1="129.495" x2="500.523" y2="1140.49" gradientUnits="userSpaceOnUse">
          <stop offset="0.354167" stop-color="#3707FF" />
          <stop offset="0.671875" stop-color="#71BACD" />
        </linearGradient>
        <linearGradient id="paint2_linear_28_536" x1="716.044" y1="39.9586" x2="342.068" y2="927.291" gradientUnits="userSpaceOnUse">
          <stop offset="0.0688978" stop-color="#142594" />
          <stop offset="0.354167" stop-color="#3707FF" />
          <stop offset="0.851316" stop-color="#71BACD" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BigHead;