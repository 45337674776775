import { useState, useRef } from 'react'
import { Button, Input, Textarea, Spinner } from "@nextui-org/react";
import Arrow from "../assets/Arrow";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { EMAIL_SERVICE_ID, EMAIL_SERVICE_TEMPLATE, EMAIL_SERVICE_PUBLIC_KEY } from '../config/appConfig';

const fadeInAnimation = {
  initial: { y: 50, opacity: 0 },
  whileInView: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 2
    }
  },
  viewport: { once: true, amount: 0.4 }
};

const ContactUs = () => {
  const captchaRef = useRef();
  const [recaptcha, setRecaptcha] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showFailureMessage, setShowFailureMessage] = useState(false);

  const isFormValid = async () => {
    let tempErrors = {};
    let isValid = true;

    if (firstName.length <= 3) {
      tempErrors["firstName"] = true;
      isValid = false;
    }

    if (lastName.length <= 3) {
      tempErrors["lastName"] = true;
      isValid = false;
    }

    if (company.length <= 3) {
      tempErrors["company"] = true;
      isValid = false;
    }

    if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)) {
      tempErrors["email"] = true;
      isValid = false;
    }

    if (phoneNumber.length <= 7) {
      tempErrors["phoneNumber"] = true;
      isValid = false;
    }

    if (message.length <= 20) {
      tempErrors["message"] = true;
      isValid = false;
    }

    const recaptcha = await setRecaptchaToken();
    if (recaptcha === null) {
      tempErrors["recaptcha"] = true;
      isValid = false;
    }

    setErrors({ ...tempErrors });
    return isValid;
  };

  const setRecaptchaToken = async () => {
    const reCaptchaToken = await captchaRef.current?.executeAsync();
    setRecaptcha(reCaptchaToken);
    captchaRef.current.reset();

    return reCaptchaToken;
  }

  const onFinish = async () => {
    if (!await isFormValid()) {
      return;
    }

    setLoading(true);

    const SendMessage = async () => {
      try {
        const params = {
          'g-recaptcha-response': recaptcha,
          from_name: `${company} - ${firstName} ${lastName}`,
          message:
            `
            ${message} 
            
            EMAIL: ${email}
            TELEFONO: ${phoneNumber}
          `,
        }

        await emailjs.send(EMAIL_SERVICE_ID, EMAIL_SERVICE_TEMPLATE, params, EMAIL_SERVICE_PUBLIC_KEY);    
        setRecaptcha(null);

        setShowSuccessMessage(true);
        setShowFailureMessage(false);
      } catch (_) {
        setShowSuccessMessage(false);
        setShowFailureMessage(true);
      } finally {
        setLoading(false);
      }
    }

    SendMessage();
  }

  return (
    <motion.div
      className="
        grid gap-2 
        max-lg:grid-flow-row 
        xl:grid-cols-2 
        mx-[60px] max-sm:mx-[15px]
        mb-[120px] max-sm:my-[80px]"
      initial={fadeInAnimation.initial}
      whileInView={fadeInAnimation.whileInView}
      viewport={fadeInAnimation.viewport}
      id="contactUs"
    >
      <div className="flex justify-center max-lg:items-start items-center">
        <div>
          <p className="font-extrabold max-sm:text-4xl text-7xl">
            Contactanos
          </p>
          <div className="w-[46px] h-[4px] my-5 bg-[#3707FF]" />
          <p className="max-sm:text-md text-md">
            Conectenos si quieres visibilizar tu empresa u organismo como un entorno mentalmente saludable.
          </p>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        {loading && <Spinner label="Enviando Mensaje..." color="default" labelColor="foreground" className="my-4" />}
        <form className={`contactUsForm flex flex-col items-start mt-8
            xl:w-[75%]
            md:w-[500px] 
            w-[100%] max-md:max-w-[500px]
            ${(showFailureMessage || showSuccessMessage || loading) && "hidden"}`}
        >
          <div className="flex sm:flex-row flex-col sm:gap-6 w-full">
            <Input
              type="text"
              label="Nombre"
              variant="underlined"
              className="mt-2"
              onChange={(e) => setFirstName(e.target.value)}
              errorMessage={errors.firstName && "Escribe un nombre de al menos 3 caracteres."}
            />
            <Input
              type="text"
              label="Apellido"
              variant="underlined"
              className="mt-2"
              onChange={(e) => setLastName(e.target.value)}
              errorMessage={errors.lastName && "Escribe un apellido de al menos 3 caracteres."}
            />
          </div>
          <Input
            type="text"
            label="Empresa"
            variant="underlined"
            className="mt-2"
            onChange={(e) => setCompany(e.target.value)}
            errorMessage={errors.company && "Escribe una de empresa de al menos 3 caracteres."}
          />
          <Input
            type="phone"
            label="Teléfono "
            variant="underlined"
            className="mt-2"
            onChange={(e) => setPhoneNumber(e.target.value)}
            errorMessage={errors.phoneNumber && "Escribe un numero de teléfono valido."}
          />
          <Input
            type="email"
            label="Correo Electrónico"
            variant="underlined"
            className="mt-2"
            onChange={(e) => setEmail(e.target.value)}
            errorMessage={errors.email && "Escribe un correo electrónico valido."}
          />
          <Textarea
            label="Mensaje"
            variant="underlined"
            className="mt-6"
            labelPlacement="outside"
            onChange={(e) => setMessage(e.target.value)}
            errorMessage={errors.message && "Escribe un mensaje de almenos 20 caracteres."}
          />
          
          <ReCAPTCHA
            size='invisible'
            ref={captchaRef}
            sitekey="6LfQ8dApAAAAAKSR5oDq9EkACjZvlUFH85fFVJ2b"
          />
          {errors.recaptcha && <span className="text-tiny text-red-500">Resuelva el ReCaptcha para enviar el mensaje.</span>}

          <Button className="contactUsFormButton" onClick={() => onFinish()}>
            Enviar <Arrow color="white" />
          </Button>
        </form>

        {showSuccessMessage && <p className="text-green-500 font-semibold my-4">Mensaje enviado con exito, muchas gracias.</p>}
        {showFailureMessage && <p className="text-red-500 font-semibold my-4">Ha ocurrido un error al enviar el mesnaje, por favor intentelo mas tarde.</p>}


      </div>
    </motion.div>
  )
};

export default ContactUs;
