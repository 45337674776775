import Bulb from "../assets/Bulb";
import EMSLogo from "../assets/EMSLogo";
import { motion } from "framer-motion";
import Linkedin from "../assets/Linkedin"

const fadeInAnimation = {
  initial: { y: 50, opacity: 0 },
  whileInView: { 
    y: 0, 
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 1.5
    }
  },
  viewport: { once: true, amount: 0.2 }
};

const About = () => {
  
  return (
    <div className="grid gap-2 max-lg:grid-flow-row xl:grid-cols-2 mx-[60px] max-sm:mx-[15px] mt-[120px] mb-[145px] max-sm:my-[80px]" id="about">
      {/* Columna 1: Imagen */}
      <div className="flex justify-center max-lg:items-start items-center">
        <Bulb />
      </div>
      
      {/* Columna 2: Contenido */}
      <motion.div className="max-xl:order-first" initial={fadeInAnimation.initial} whileInView={fadeInAnimation.whileInView} viewport={fadeInAnimation.viewport}>
        <p className="text-5xl max-lg:text-4xl leading-[123%] font-thin">Acerca de</p>
        <div className="w-[46px] h-[4px] my-5 bg-[#3707FF]"/>
        <p className="leading-[178.5%]">
          On Mental es un sistema que mide y certifica entornos mentalmente saludables, mediante un método único en el mercado. Brindamos un sello que identifica tu empresa u organización como un lugar donde la salud mental de los colaboradores importa.
        </p>
        <p className="leading-[178.5%] mt-5">
          On Mental otorga el primer sello disponible en el mercado latinoamericano para distinguir a cualquier organización mayor a 10 trabajadores como un ambiente saludable para trabajar.
        </p>
        <p className="leading-[178.5%] mt-5">
          Apalancado científicamente y con un equipo de expertos que intervienen en el proceso, este sello disruptivo e innovador tiene el propósito de dejar una huella en el mundo laboral para empresas y trabajadores.
        </p>
        <EMSLogo classNames="w-[180px] mt-5 mb-6" />
        <a 
            href="https://www.linkedin.com/company/on-mental/posts/?feedView=all" 
            target="_blank" 
            rel="noopener noreferrer"
            className="flex items-center space-x-2 cursor-pointer text-[#126BC4] inline-flex group"
        >
            <Linkedin />
            <span className="text-xl font-bold align-baseline pt-0.5 group-hover:text-blue-500 transition duration-200">
                LinkedIn
            </span>
        </a>  
      </motion.div>    
    </div>
  )
};

export default About;