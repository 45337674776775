const Footer = () => {
  const CurrentYear = () => (new Date().getFullYear());

  return (
    <h1 className="text-center py-6 px-3">
      <CurrentYear /> Todos los derechos reservados. Creado por <a href="https://www.towersoftware.info/" rel="noreferrer" target="_blank" className="text-blue-600">Tower Software</a>. Mejoramos tu negocio con Software.
    </h1>
  )
}

export default Footer;