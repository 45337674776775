import React from 'react';
import { Button, Link } from '@nextui-org/react';
import BigHead from "../assets/BigHead";
import Arrow from "../assets/Arrow";
import { motion } from "framer-motion";
import "../styles/home.css";

const fadeInAnimation = {
  initial: { y: 50, opacity: 0 },
  whileInView: (duration) => ({ 
    y: 0, 
    opacity:  1,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: duration
  }}),
};

const Home = () => {
  
  return (
    <div className="h-[100vh] flex justify-center items-center ml-[60px] max-sm:ml-[15px]" id="home">
      <BigHead />
      <motion.div initial="initial" whileInView="whileInView" viewport={{ once: true, amount: 0.4 }}>
        <motion.p variants={fadeInAnimation} custom={3} className="homeBigParagraph text-5xl sm:text-5xl md:text-6xl lg:text-6xl 2xl:text-8xl">
          Certificamos Entornos Mentalmente Saludables 
        </motion.p>
        <motion.div variants={fadeInAnimation} custom={4}>
          <Button className="goToEMSMethodButton" href="#benefits" as={Link}>
            Sobre el método EMS <Arrow color="white" />
          </Button>
        </motion.div>
      </motion.div>
    </div>
  )
};

export default Home;